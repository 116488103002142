








































































































































































































import Vue from "vue";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import configProvider from "@common/services/configProvider";
import stateManager from "@common/services/stateManager";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import { Html5Qrcode } from "html5-qrcode";
import { encode } from "@common/util/base64Util";

export default Vue.extend({
  components: { GlobalErrorSnackBar },
  data() {
    return {
      self: this,
      mini: true,
      expandedItems: {},
      userManager,
      logoUrl: configProvider.get("LOGO_URL"),
      items: [
        {
          title: "Organizations",
          icon: "mdi-family-tree",
          to: "/app/tenants",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"]);
          },
        },
        {
          title: "Map",
          icon: "mdi-map",
          to: "/app/maps",
          condition() {
            return !userManager.isUserMobile();
          },
        },
        {
          title: "Site Area",
          icon: "mdi-map-marker-circle",
          to: "/app/site_area",
          condition() {
            return !userManager.isUserMobile() && userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true);
          },
        },
        {
          title: "Sites",
          icon: "mdi-map-marker",
          to: "/app/charging_locations",
          condition() {
            return !userManager.isUserMobile();
          },
        },
        {
          title: "Sites",
          icon: "mdi-map-marker",
          to: "/app/charging_locations_m",
          condition() {
            return userManager.isUserMobile();
          },
        },
        {
          title: "Retailers",
          icon: "mdi-select-group",
          to: "/app/retailers",
          condition() {
            return userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true);
          },
        },
        {
          title: "Charging Membership",
          icon: "mdi-account-star",
          to: "/app/charging_memberships",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        // {
        //   title: "Promotion Moneys",
        //   icon: "mdi-cash-multiple ",
        //   to: "/app/promotion_moneys",
        //   condition() {
        //     return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
        //   },
        // },
        {
          title: "Promotional News",
          icon: " mdi-newspaper",
          to: "/app/promotional_news",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"]);
          },
        },
        {
          title: "Licenses",
          icon: " mdi-key",
          to: "/app/licenses",
          condition() {
            return userManager.checkRole(["systemAdmin"]);
          },
        },
        {
          title: "Companion",
          icon: "mdi-account-network",
          to: "/app/companion",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"]);
          },
        },
        {
          title: "Promotions",
          icon: "mdi-tag",
          to: "/app/promotions",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"]);
          },
        },
        {
          title: "Cashback",
          icon: "mdi-cash-refund",
          to: "/app/cashback",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"], false, true);
          },
        },
        {
          title: "Revenue",
          icon: "mdi-cash-refund",
          to: "/app/cashback/" + userManager.getTenantId() + '?table_options=' + encode(JSON.stringify({
            "customPerPage": 0,
            "dataOptions": {
              "groupBy": [],
              "groupDesc": [],
              "itemsPerPage": 50,
              "multiSort": false,
              "mustSort": false,
              "page": 1,
              "sortBy": [
                "date"
              ],
              "sortDesc": [
                true
              ]
            },
            "filters": [
              {
                "key": "point",
                "operator": "greater_than",
                "value": 0
              }
            ]
          })),
          condition() {
            return userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], false, true);
          },
        },
        {
          title: "My Charger",
          icon: "mdi-battery-charging-high",
          to: "/app/my_charger/home_m",
          condition() {
            return userManager.isUserMobile();
          }
        },
        {
          title: "Charging Stations",
          icon: "mdi-ev-station",
          to: "/app/charging_stations",
          condition() {
            return !userManager.isGuest() && !userManager.isUserMobile();
          },
        },
        {
          title: "Charging Stations",
          icon: "mdi-ev-station",
          to: "/app/charging_stations_m",
          condition() {
            return !userManager.isGuest() && userManager.isUserMobile();
          },
        },
        {
          title: "Charging Sessions",
          icon: "mdi-clipboard-text-clock-outline",
          to: "/app/charging_sessions",
          condition() {
            return !userManager.isGuest() && !userManager.isUserMobile();
          },
        },
        {
          title: "Plugged Sessions",
          icon: "mdi-clipboard-text-clock-outline",
          to: "/app/plugged_charging_sessions",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        {
          title: "Charger Vendors",
          icon: "mdi-hexagon-slice-4",
          to: "/app/charger_vendors",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        {
          title: "Cars",
          icon: "mdi-car",
          to: "/app/cars",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "user"]);
          },
        },
        {
          title: "RFID Cards",
          icon: "mdi-card",
          to: "/app/tags",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        {
          title: "Users",
          icon: "mdi-account-multiple",
          to: "/app/users",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        {
          title: "Retailers",
          icon: "mdi-select-group",
          to: "/app/retailers",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"], false, true);
          },
        },
        // {
        //   title: "Autocharge Vehicles",
        //   icon: "mdi-car",
        //   to: "/app/ocpp_autocharge_found_vehicles",
        //   condition() {
        //     return (
        //       configProvider.get("AUTOCHARGE_ENABLED") &&
        //       userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"])
        //     );
        //   },
        // },
        {
          title: "Statistics",
          icon: "mdi-chart-bar",
          to: "/app/statistics",
          condition() {
            return userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true);
          },
        },
        {
          title: "Error Stations",
          icon: "mdi-alert-octagon-outline",
          to: "/app/error_stations",
          condition() {
            return userManager.checkRole(["systemAdmin",  "admin", "cpoAdmin", "cpoMonitor"]);
          },
        },
        {
          title: "OCPP Logs",
          icon: "mdi-connection",
          to: "/app/ocpp_logs",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin"]);
          },
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          key: "settings",
          condition() {
            return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "user"]);
          },
          items: [
            {
              title: "Payment Exchange Ratio",
              to: "/app/settings/payment_exchange_ratio",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
              },
            },
            {
              title: "Payment Methods",
              to: "/app/settings/payment_methods",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]);
              },
            },
            {
              title: "Payment Transactions",
              to: "/app/settings/payment_transactions",
              condition() {
                return (
                  userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "user"]) &&
                  !userManager.isUserMobile()
                );
              },
            },
            {
              title: "Payment Transactions",
              to: "/app/settings/payment_transactions_m",
              condition() {
                return (
                  userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "user"]) &&
                  userManager.isUserMobile()
                );
              },
            },
            {
              title: "Time Zone",
              to: "/app/settings/time_zone",
            },
            {
              title: "Notify To Users",
              to: "/app/settings/notify_to_users",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin"]);
              },
            },
            {
              title: "Terms and Conditions",
              to: "/app/settings/terms_and_conditions",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin"]);
              },
            },
            {
              title: "Privacy Policy",
              to: "/app/settings/privacy_policy",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin"]);
              },
            },
            {
              title: "FAQ",
              to: "/app/settings/faq",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin"]);
              },
            },
            {
              title: "Mail",
              to: "/app/settings/mail",
              condition() {
                return userManager.checkRole(["systemAdmin", "admin"]);
              },
            }
          ],
        },
      ],
      userInfo: {},
      point: undefined,
      pointLoading: false,
      html5QrCode: null,
    };
  },
  watch: {
    mini(value) {
      stateManager.set("navigationMenuMini", value);
    },
  },
  methods: {
    async logout() {
      userManager.deleteAccessToken();
      appService.deleteActiveTenant();
      this.$router.push("/login");
    },
    async resetActiveTenant() {
      appService.deleteActiveTenant();
    },
    async updateExpandedItem(key, value) {
      this.expandedItems[key] = value;
      stateManager.set("navigationMenuExpandedItems", this.expandedItems);
    },
    async qrScan() {
      let html5QrCode: Html5Qrcode = this.html5QrCode;
      if (!html5QrCode) {
        html5QrCode = new Html5Qrcode("qr-reader");
        this.html5QrCode = html5QrCode;
      }

      if (this.html5QrCode?.isScanning) {
        this.html5QrCode.stop();
      } else {
        html5QrCode.start(
          {
            facingMode: "environment",
          },
          {
            fps: 10,
          },
          (decodedText) => {
            location.href = decodedText;
            html5QrCode.stop();
          },
          () => {
          }
        );
      }
    },
  },
  async created() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.mini = stateManager.get("navigationMenuMini", false);
    }
    const obj = stateManager.get("navigationMenuExpandedItems", {});
    for (const k in obj) {
      this.expandedItems[k] = obj[k];
    }

    this.userInfo = userManager.getUserInfo();

    if (!userManager.isGuest() && userManager.checkRole(["user"], true)) {
      this.pointLoading = true;
      {
        const { point } = await coreApiClient.call("users", "findById", {
          id: userManager.getUserInfo()._id,
        });
        this.point = point || 0;
      }
      this.pointLoading = false;
    }
  },
});
